import Image from 'next/legacy/image';
import { Button } from 'components/Button';

import cx from 'classnames';
import styles from './DialoguePage.module.scss';
import { NextLink } from '../../components/NextLink';
import { APPSTORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../../consts';

export type DialoguePageProps = {
  iconLink: string;
  iconWidth?: number | `${number}`;
  title: string;
  description: string | string[];
  primaryButton?: {
    action: () => void;
    caption: string;
    id?: string;
  };
  secondaryButton?: {
    action: () => void;
    caption: string;
    id?: string;
  };
  lightTitle?: boolean;
};

export const DialoguePage = ({
  iconLink,
  iconWidth = '80',
  title,
  description,
  primaryButton,
  secondaryButton,
  lightTitle,
  isLarge,
  withLinks,
}: DialoguePageProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainIcon}>
        <Image src={iconLink} width={iconWidth} height="80" alt={title} />
      </div>
      <div>
        <h2 className={cx({ [styles.lightText]: lightTitle })}>{title}</h2>
        {description && Array.isArray(description) ? (
          description.map((text, index) => <p key={index}>{text}</p>)
        ) : (
          <p className={isLarge ? styles.large : styles.default}>{description}</p>
        )}
      </div>
      {withLinks && (
        <div className={styles.downloadBannerIcons}>
          <NextLink href={APPSTORE_APP_LINK} newTab={true}>
            <Image
              alt="App Store"
              src="/App_Store_Badge.svg"
              width={144}
              height={48}
              layout="fixed"
              objectFit="cover"
            />
          </NextLink>
          <NextLink href={GOOGLE_PLAY_APP_LINK} newTab={true}>
            <Image
              alt="Google Pay"
              src="/Google_Play_Store.svg"
              width={162}
              height={48}
              layout="fixed"
              objectFit="cover"
            />
          </NextLink>
        </div>
      )}
      <div className={styles.buttons}>
        {secondaryButton && (
          <Button
            id={secondaryButton.id}
            caption={secondaryButton.caption}
            action={secondaryButton.action}
            shape="slimy"
            buttonType="white"
          />
        )}
        {primaryButton && (
          <Button id={primaryButton.id} caption={primaryButton.caption} action={primaryButton.action} shape="slimy" />
        )}
      </div>
    </div>
  );
};
