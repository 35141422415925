import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import {
  AdminApi,
  AdminApiDisableAction,
  AdminListRestParams,
  AdminPanelTab,
  BaseResponseData,
  PanelButtonProps,
  TableHeader,
} from 'types';
import { enableAdminPanelUnit, disableAdminPanelUnit, deleteAdminPanelUnit } from 'api';
import { capitalizeAllWords } from 'utils/formatters';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  selectAdminPanelData,
  getFilteredAdminPanel,
  setAdminPanelFilters,
  selectCurrentUser,
  closeModal,
  openModal,
  openSnackBar,
  selectActiveTab,
} from 'store';
import { PermissionChecker } from 'containers/PermissionVerificator';
import { UnderHeaderTabs } from 'components/UnderHeaderTabs';
import { AdminPanelWrapper } from '../AdminPanelWrapper';
import { CommonContentTable, EditAction } from '../CommotContentTable';
import { SelectableAction } from 'components/Table';
import { ReduxDrivenSearchInput } from './ReduxDrivenSearchInput';
import { ReduxDrivenPagination } from './ReduxDrivenPagination';

export type AdminPanelBoardProps = {
  adminPanelTableHeaders: TableHeader[];
  additionalFilters?: ReactElement;
  api: AdminApi;
  apiInstanceName?: string;
  apiHeading: string;
  buttonProps?: PanelButtonProps;
  children?: ReactElement;
  panelChildren?: ReactElement;
  customAction?: EditAction | EditAction[];
  customDeleteAction?: EditAction;
  customDeleteIcon?: string;
  customEditIcon?: string;
  customHideAction?: EditAction;
  disableMode?: AdminApiDisableAction;
  fixedActions?: boolean;
  hideButton?: boolean;
  permissionPrefix?: string;
  searchPlaceholder?: string;
  sidebarMarker?: string;
  tableHeadChildren?: any;
  tab?: string;
  tabProps?: {
    tabs: AdminPanelTab[];
  };
  title?: string;
  viewActions?: EditAction[];
  withSearch?: boolean;
  withHideAction?: boolean;
  withBlockAction?: boolean;
  blockActionProps?: object;
  withEditAction?: boolean;
  editActionProps?: object;
  withDeleteAction?: boolean;
  // classes
  leftToolbarClass?: string;
  rightToolbarClass?: string;
  // props for table with select rows
  selectableTable?: boolean;
  selectableTableActions?: SelectableAction[];
};

export const AdminPanelBoard = (props: AdminPanelBoardProps) => {
  const {
    additionalFilters,
    adminPanelTableHeaders,
    api,
    apiHeading,
    apiInstanceName = '',
    buttonProps = {} as PanelButtonProps,
    children,
    panelChildren,
    customAction,
    customDeleteAction,
    customEditIcon,
    customHideAction,
    fixedActions,
    hideButton,
    permissionPrefix = api,
    searchPlaceholder,
    sidebarMarker,
    tab,
    tableHeadChildren,
    tabProps,
    title,
    viewActions,
    withBlockAction,
    blockActionProps,
    withDeleteAction = true,
    withHideAction = true,
    withEditAction = true,
    editActionProps = true,
    withSearch = true,
    leftToolbarClass,
    rightToolbarClass,
    selectableTable,
    selectableTableActions,
  } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { data, filters = {}, isLoading } = useAppSelector(selectAdminPanelData);
  const activeTab = useAppSelector(selectActiveTab) || tab;
  const currentUser = useAppSelector(selectCurrentUser);
  const { type } = filters;

  const canEditData = currentUser?.role === 'super_admin' || currentUser?.permissions?.includes(`${permissionPrefix}_edit`);

  const showSuccess = (message: string) => {
    dispatch(
      openSnackBar({
        header: message,
      }),
    );
  };

  const showError = (message: string) => {
    dispatch(
      openSnackBar({
        mode: 'error',
        header: message,
      }),
    );
  };

  const handleResponse = (res: BaseResponseData<any>, action: string) => {
    if (res.ok) {
      showSuccess(`${apiInstanceName} ${action} successfully`);
      dispatch(getFilteredAdminPanel(filters));
    } else {
      showError(res.message || res.error || `Sorry, request failed, please, try later.`);
    }
  };

  const getBlockModalProps = (id: string, instance?: any) => {
    const description =
      instance.username && instance.has_active_orders
        ? 'User has active orders. Do you still want to block the user?'
        : 'Do you really want to block this user?';

    return {
      iconSrs: '/icons/security/LockKeyWarn.svg',
      title: 'Block this user?',
      description: description,
      actionButton: {
        caption: 'Block',
        buttonType: 'red',
        action: () => {
          dispatch(closeModal());
          enableAdminPanelUnit(api, id, 'block').then((res) => {
            handleResponse(res, 'blocked');
          });
        },
      },
      cancelButton: {
        caption: 'Cancel',
        buttonType: 'white',
        action: () => {
          dispatch(closeModal());
        },
      },
    };
  };

  const getDeleteModalProps = (id: string, hasItems?: boolean) => ({
    iconSrs: '/icons/office/TrashWarn.svg',
    title: hasItems ? `The ${apiInstanceName} has linked gear profiles.` : `Delete this ${apiInstanceName}?`,
    description: hasItems
      ? `They will be hidden from the web and mobile unless users get the ${apiInstanceName} changed. Are you sure you still want to delete the ${apiInstanceName}?`
      : `Do you really want to delete this ${apiInstanceName}? This process\n can not be undone.`,
    actionButton: {
      caption: 'Delete',
      buttonType: 'red',
      action: () => {
        dispatch(closeModal());
        deleteAdminPanelUnit(api, id).then((res) => {
          handleResponse(res, 'deleted');
        });
      },
    },
    cancelButton: {
      caption: 'Cancel',
      buttonType: 'white',
      action: () => {
        dispatch(closeModal());
      },
    },
  });

  const getDeletionWarningModalProps = () => ({
    iconSrs: '/icons/security/ProhibitRed.svg',
    title: `Can not provide this action!`,
    description: `The ${apiInstanceName} has children. You should unlink children in order to delete the parent one.`,
    cancelButton: {
      caption: 'Close',
      buttonType: 'white',
      action: () => {
        dispatch(closeModal());
      },
    },
  });

  const handleDisableAdminPanelUnit = (id: string) => {
    disableAdminPanelUnit(api, id, 'hide').then((res) => {
      handleResponse(res, 'hidden');
    });
  };

  const getHideWarningModalProps = (id: string) => ({
    iconSrs: '/icons/security/WarningCircleWarn.svg',
    title: `The ${apiInstanceName} has linked gear profiles`,
    description: `They will be hidden from the web and mobile unless users get the ${apiInstanceName} changed. Are you sure you still want to hide the ${apiInstanceName}?`,
    actionButton: {
      caption: 'Hide',
      buttonType: 'red',
      action: () => {
        dispatch(closeModal());
        handleDisableAdminPanelUnit(id);
      },
    },
    cancelButton: {
      caption: 'Cancel',
      buttonType: 'white',
      action: () => {
        dispatch(closeModal());
      },
    },
  });

  const getAppropriateBlockAction = (activeTab?: string) => {
    if (activeTab === 'unblocked') {
      return {
        name: 'block',
        icon: '/icons/security/LockKeySoft.svg',
        action: (id: string, instance: any) => {
          dispatch(openModal(getBlockModalProps(id, instance)));
        },
        ...(blockActionProps || {}),
      };
    }
    if (activeTab === 'blocked') {
      return {
        name: 'unblock',
        icon: '/icons/security/LockKeyOpenSoft.svg',
        action: (id: string) => {
          disableAdminPanelUnit(api, id, 'block').then((res) => {
            handleResponse(res, 'unblocked');
          });
        },
        ...(blockActionProps || {}),
      };
    }
    return null;
  };

  const editAction = {
    name: 'edit',
    icon: customEditIcon || '/icons/design/PencilLineSoft.svg',
    action: (id: string, rowItem: any) => {
      router.push(`/admin-panel/${api}/${id}${rowItem.user_id ? `?user_id=${rowItem.user_id}`: ''}`).then(() => {});
    },
    ...(editActionProps || {}),
  };

  const hideAction = customHideAction ? customHideAction : {
    name: 'hide',
    conditionField: 'is_hide',
    icon: '/icons/people/EyeSlash.svg',
    altIcon: '/icons/people/EyeBlue.svg',
    action: (id: string) => {
      enableAdminPanelUnit(api, id, 'hide').then((res) => {
        handleResponse(res, 'displayed');
      });
    },
    altAction: (id: string, item?: Record<string, any>) => {
      if (!item?.has_items) {
        handleDisableAdminPanelUnit(id);
      } else {
        dispatch(openModal(getHideWarningModalProps(id)));
      }
    },
  };

  const blockAction = getAppropriateBlockAction(activeTab);

  const deleteAction = {
    name: 'delete',
    icon: '/icons/office/TrashWarn.svg',
    action: (id: string, item?: Record<string, any>) => {
      if (!item?.has_children) {
        dispatch(openModal(getDeleteModalProps(id, item?.has_items)));
      } else {
        dispatch(openModal(getDeletionWarningModalProps()));
      }
    },
  };

  const editAdminPanelActions: any[] = [];
  if (customAction) {
    if (Array.isArray(customAction)) {
      editAdminPanelActions.push(...customAction);
    } else {
      editAdminPanelActions.push(customAction);
    }
  }
  withEditAction && editAdminPanelActions.push(editAction);
  withHideAction && editAdminPanelActions.push(hideAction);
  withBlockAction && blockAction && editAdminPanelActions.push(blockAction);
  withDeleteAction && editAdminPanelActions.push(customDeleteAction || deleteAction);

  const sortAdminPanel = (field: string, direction: 'asc' | 'desc' | 'none') => {
    console.log('>>>>> sortAdminPanel ', direction)
    if (direction === 'none') {
      const filterWrapper: AdminListRestParams = { ...filters };
      delete filterWrapper['order'];
      delete filterWrapper['sort'];
      dispatch(
        setAdminPanelFilters({
          ...filterWrapper,
        }),
      );
      dispatch(
        getFilteredAdminPanel({
          ...filterWrapper,
        }),
      );
    } else {
      dispatch(setAdminPanelFilters({ ...filters, sort: field, order: direction, page: 1 }));
      dispatch(getFilteredAdminPanel({ ...filters, sort: field, order: direction, page: 1 }));
    }
  };

  return (
    <PermissionChecker {...props} user={currentUser}>
      <AdminPanelWrapper title={title} heading={apiHeading} sidebarMarker={sidebarMarker}>
        <>
          {tabProps && <UnderHeaderTabs tabs={tabProps.tabs} active={activeTab || type} />}
          <CommonContentTable
            additionalFilters={additionalFilters ? additionalFilters : undefined}
            buttonCaption={buttonProps.caption ? buttonProps.caption : `Add New ${capitalizeAllWords(apiInstanceName)}`}
            buttonIcon={buttonProps.icon ? buttonProps.icon : '/icons/design/PlusCircle.svg'}
            buttonCallback={
              buttonProps.callback ? buttonProps.callback : () => router.push(`/admin-panel/${api}/create`)
            }
            buttonType={buttonProps.buttonType}
            canEdit={!!canEditData}
            data={data || []}
            editActions={editAdminPanelActions}
            fixedActions={fixedActions}
            headChildren={tableHeadChildren}
            hideButton={hideButton || !canEditData}
            isLoading={isLoading}
            onSort={sortAdminPanel}
            pagination={<ReduxDrivenPagination />}
            searchInput={withSearch ? <ReduxDrivenSearchInput placeholder={searchPlaceholder} /> : undefined}
            tableHeaders={adminPanelTableHeaders}
            viewActions={viewActions}
            leftToolbarClass={leftToolbarClass}
            rightToolbarClass={rightToolbarClass}
            selectableTable={selectableTable}
            selectableTableActions={selectableTableActions}
            panelChildren={panelChildren}
          >
            {children && children}
          </CommonContentTable>
        </>
      </AdminPanelWrapper>
    </PermissionChecker>
  );
};
