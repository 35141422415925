import { AdminApi, CurrentUser, PermissionPrefix, PermissionSuffix } from 'types';
import { ErrorPage } from './ErrorPage';
import { RedirectPage } from './RedirectPage';
import { checkIsAdmin } from 'utils/services';
import { userCan } from 'utils/permissions';

export type PermissionCheckerProps = {
  user?: CurrentUser | null;
  api?: AdminApi | string;
  permissionPrefix?: PermissionPrefix | string | PermissionPrefix[];
  suffix?: PermissionSuffix | string;
  children: any;
};

export const PermissionChecker = (props: PermissionCheckerProps) => {
  const { user, permissionPrefix, api, suffix = 'view', children } = props;
  const isRegisteredUser = !!user?.email;
  const isAdmin = checkIsAdmin(user);

  if (!isRegisteredUser) {
    return <RedirectPage url="/sign-in" />;
  }

  if (!isAdmin) {
    return <ErrorPage statusCode={403} errorMessage="Sorry, you are not allowed to view this page" />;
  }

  if (!userCan(user, permissionPrefix || api || '', suffix)) {
    return (
      <ErrorPage statusCode={403} errorMessage="Sorry, you are not allowed to view this page" url="/admin-panel" />
    );
  }

  return <>{children}</>;
};
