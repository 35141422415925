import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './PaginationProvider.module.scss';

export type PaginationButtonProps = {
  value: number | string;
  iconSrc?: string;
  callback?: (value: number) => void;
  active?: boolean;
  disabled?: boolean;
};

export const PaginationButton = ({ value, iconSrc, callback, active, disabled }: PaginationButtonProps) => {
  const icon = iconSrc && `${iconSrc}${disabled ? 'Soft' : 'Dark'}.svg`;

  return (
    <div
      className={cx(styles.paginationButton, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={() => {
        !disabled && typeof value === 'number' && callback && callback(value);
      }}
    >
      {icon ? (
        <Image src={`${iconSrc}${disabled ? 'Soft' : 'Dark'}.svg`} height="16" width="16" alt={`${value}`} />
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};
