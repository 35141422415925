import { useState, useEffect } from 'react';
import { TableHeader, TableSortAction } from 'types';
import { TableHeaderCell } from './TableHeaderCell';
import { SelectHeaderCell } from './SelectHeaderCell';
import { TableRow } from './TableRow';
import { SelectActions } from './SelectActions';
import { MessageBlock } from '../MessageBlock';

import cx from 'classnames';
import styles from './Table.module.scss';

export type SelectableAction = {
  icon: string;
  iconDisabled: string;
  handle: (selectedItems: any[]) => void;
  text?: string;
  disabled?: boolean;
};

export type TableProps = {
  onSort?: TableSortAction;
  headers?: TableHeader[];
  content?: any[];
  actions?: any;
  isLoading?: boolean;
  fixedActions?: boolean;
  noBorder?: boolean;
  // props for table with select rows
  selectable?: boolean;
  selectableActions?: SelectableAction[];
};

export const Table = ({
  onSort,
  headers = [],
  content = [],
  actions,
  isLoading,
  fixedActions,
  noBorder,
  selectable,
  selectableActions,
}: TableProps) => {
  const [tableRows, setTableRows] = useState(content);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);

  useEffect(() => {
    setTableRows(content);
    setSelectedItemsCount(0);
  }, [content]);

  const handleSelectRow = (index: number, selected: boolean) => {
    setTableRows(
      tableRows?.map((data, i) => i === index ? { ...data, selected } : data)
    );

    setSelectedItemsCount(selected ? selectedItemsCount + 1 : selectedItemsCount - 1);
  }

  const handleSelectAll = () => {
    const selected = selectedItemsCount !== tableRows?.length;

    setTableRows(
      tableRows?.map(data => ({ ...data, selected }))
    );

    setSelectedItemsCount(selected ? (tableRows?.length || 0) : -0);
  }

  const handleSelectableAction = (action: SelectableAction) => {
    action.handle(tableRows?.filter(row => row.selected || false));
  }

  return (
    <div>
      {selectableActions?.length > 0 && (
        <SelectActions
          actions={selectableActions || []}
          active={selectedItemsCount > 0}
          onAction={handleSelectableAction}
        />
      )}
      <div
        className={cx({
          [styles.noBorderWrapper]: noBorder,
          [styles.tableWrapper]: !noBorder,
        })}
      >
        <table className={styles.container}>
          <thead className={styles.headerBlock}>
          <tr>
            {selectable && (
              <SelectHeaderCell
                noBorder={noBorder}
                selected={selectedItemsCount === tableRows?.length}
                onSelect={handleSelectAll}
              />
            )}
            {headers?.map((header) => {
              return (
                <TableHeaderCell
                  key={header.name}
                  title={header.title}
                  columnName={header.name}
                  isSortable={header.isSortable}
                  alignItems={header.alignItems}
                  api={header.api}
                  onSort={onSort}
                  noBorder={noBorder}
                />
              );
            })}
            {actions?.length > 0 && (
              <th className={styles.actionsHeader}>
                <span>Actions</span>
              </th>
            )}
          </tr>
          </thead>

          {isLoading && <div className={cx({ [styles.loader]: isLoading })} />}

          <tbody>
          {tableRows?.map((rowItem, index) => (
            <TableRow
              key={index}
              index={index}
              headers={headers || []}
              rowItem={rowItem}
              actions={actions}
              fixedActions={fixedActions}
              noBorder={noBorder}
              selectable={selectable}
              onSelect={handleSelectRow}
            />
          ))}
          </tbody>
        </table>
        {!tableRows?.length && (
          <div>
            <MessageBlock
              imgSrc="/icons/weather/WindYellow.svg"
              title="You don’t have any content"
              description="Please try to change search parameters or create new entries"
            />
          </div>
        )}
      </div>
    </div>
  );
};
