import {
  useAppSelector,
  useAppDispatch,
  selectAdminPanelFilters,
  selectAdminPanelPages,
  setAdminPanelFilters,
  getFilteredAdminPanel,
} from 'store';
import { PaginationProvider } from 'components/PaginationProvider/PaginationProvider';

export const ReduxDrivenPagination = () => {
  const dispatch = useAppDispatch();
  const pages = useAppSelector(selectAdminPanelPages);
  const filters = useAppSelector(selectAdminPanelFilters);
  if (!pages) return null;

  const { current_page, from, last_page, to, total } = pages;

  const onPaginationChange = (value: number) => {
    dispatch(setAdminPanelFilters({ ...filters, page: value }));
    dispatch(getFilteredAdminPanel({ ...filters, page: value }));
  };

  return (
    <PaginationProvider
      currentPage={current_page}
      from={from}
      lastPage={last_page}
      to={to}
      total={total}
      onPaginationChange={onPaginationChange}
    />
  );
};
