import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { CurrentUser, MenuResponse } from 'types';
import { CustomerPageWrapper } from '../CustomerPageWrapper';
import { AdminPanelWrapper } from '../AdminPanel';
import { DialoguePage } from '../DialoguePage';
import { getPageH1Title } from 'utils/seoFormatters';

export type ErrorPageProps = {
  statusCode?: number | string;
  errorMessage?: string;
  title?: string;
  url?: string;
  user?: CurrentUser;
  menuData?: MenuResponse;
  primaryButton?: {
    caption: string;
    action: () => void;
  };
};

export const ErrorPage = ({ statusCode, title, errorMessage, primaryButton, url, menuData, user }: ErrorPageProps) => {
  const pageTitle = `${statusCode} Error | SENDY`;

  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     Sentry.captureException(`SENDY: Error Page status ${statusCode} - on url ${window.history.state.as}`);
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorElement = () => {
    return (
      <DialoguePage
        iconLink="/icons/office/FileXLight.svg"
        title={title || (statusCode as string) || 'Oops! Page not found'}
        description={errorMessage || ' '}
        primaryButton={primaryButton}
      />
    );
  };

  if (url?.startsWith('/admin-panel') || url?.startsWith('/account-settings')) {
    return (
      <AdminPanelWrapper title={pageTitle} heading={String(statusCode)}>
        {errorElement()}
      </AdminPanelWrapper>
    );
  }

  return (
    <CustomerPageWrapper
      menuData={menuData}
      user={user}
      headTitle={pageTitle}
      seoPageName="error"
      hiddenComponentProps={statusCode === 404 ? { title: getPageH1Title('error404') } : undefined}
    >
      {errorElement()}
    </CustomerPageWrapper>
  );
};
