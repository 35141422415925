import Image from 'next/legacy/image';
import styles from './ListItem.module.scss';

export type ListItemProps = {
  changeParams?: (value: string | number) => void;
  value: string | number;
  caption?: string;
  content: Array<string | number>;
};

export const ListItem = ({ changeParams, caption, value, content }: ListItemProps) => {
  const stopOwnPropagation = (event: any) => {
    event.stopPropagation();
  };

  return (
    <div
      onClick={(event) => {
        if (changeParams) {
          stopOwnPropagation(event);
          changeParams(value);
        }
      }}
      className={styles.item}
    >
      {!content.includes(value) ? (
        <div className={styles.check} />
      ) : (
        <div className={styles.active}>
          <Image alt="" width="20" height="20" src="/icons/development/Check.svg" />
        </div>
      )}
      <p>{caption}</p>
    </div>
  );
};
