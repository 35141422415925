import Image from "next/image";

import styles from './RateCard.module.scss';

export type RateCardProps = {
  iconSrc: string;
  value: string;
  title: string;
}

export const RateCard = ({iconSrc, value, title}: RateCardProps) => {

  return (
    <div className={styles.container}>
    <div className={styles.iconWrapper}>
      <Image src={iconSrc} width={24} height={24} alt={title} />
    </div>
      <div className={styles.infoBlock}>
        {value && <h2>{value}</h2>}
        {title && <p>{title}</p>}
      </div>
    </div>
  )
}
