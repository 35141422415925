import {useRouter} from "next/router";
import {useEffect} from "react";


export const RedirectPage = ({url}: {url: string}) => {
  const router = useRouter();

  useEffect(() => {
    router.push(url).then();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
