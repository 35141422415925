import Image from 'next/legacy/image';
import styles from './MessageBlock.module.scss';

export type MessageBlockProps = {
  imgSrc?: string;
  title?: string;
  description?: string;
};

export const MessageBlock = ({ imgSrc, title, description  }: MessageBlockProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.MessageBlock}>
        {imgSrc && <Image alt="Image" src={imgSrc} width="40" height="40" />}
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
