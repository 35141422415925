import { useMemo, useState, useEffect } from 'react';
import { getDateFilterOptions, getFromToDateStrings } from 'utils/services';
import { DashboardDateFilterOptionId } from './SalesReportPanelFilters';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { AdminExportButton } from 'components/AdminExportButton';

import styles from './Dashboard.module.scss';

export const BalancesReportPanel = () => {
  const [filters, setFilters] = useState<Record<string, Exclude<any, Function>>>({});
  const [dateFilter, setDateFilter] = useState<DashboardDateFilterOptionId>('month');
  const [rangePickerValue, setRangePickerValue] = useState<Array<string | null> | undefined>(
    getFromToDateStrings('month'),
  );

  const dateFilterOptions = useMemo(getDateFilterOptions, []);

  useEffect(() => {
    const [newFrom, newTo] = getFromToDateStrings(dateFilter) || [];

    setFilters((previous: Record<string, Exclude<any, Function>> | any) => {
      if (dateFilter === 'custom') {
        return previous;
      }
      setRangePickerValue([newFrom, newTo]);
      const newFilters = { ...previous };
      if (dateFilter === 'all') {
        delete newFilters.from_date;
        delete newFilters.to_date;
      } else {
        newFilters.from_date = newFrom;
        newFilters.to_date = newTo;
      }

      return newFilters;
    });
  }, [dateFilter]);

  const onChange = (name: string, newData: Record<string, Exclude<any, Function>> | any) => {
    setFilters((previous: Record<string, Exclude<any, Function>> | any) => {
      const newFilters = { ...previous };
      newFilters[name] = newData;

      return newFilters;
    });
  };

  const onDateFilterChanged = (dateFilter: DashboardDateFilterOptionId) => {
    setDateFilter(dateFilter);
  };

  return (
    <div className={styles.salesReportContainer}>
      <h2>Balances Report</h2>
      <div className={styles.filtersContainer}>
        <Dropdown
          label="Time Period:"
          blockUpdateByValue={true}
          isThin={true}
          options={dateFilterOptions}
          onChange={(_, value) => onDateFilterChanged(value)}
          value={dateFilter}
        />
        <DateRangePicker
          fieldName="from_date#to_date"
          onChange={onChange}
          isDisabled={dateFilter !== 'custom'}
          value={rangePickerValue?.length ? rangePickerValue : undefined}
          label="Start date:#End date:"
          zIndex={11}
        />
        <div className={styles.filtersButtonContainer}>
          <AdminExportButton
            type="balances_report"
            exportParams={filters}
          />
        </div>
      </div>
    </div>
  );
};
