import { MappableObject, TableHeader } from 'types';
import { convertSlugToCaption } from 'utils/formatters';
import { TableCell } from './TableCell';
import { ActionCell } from './ActionSell';
import { SelectCell } from './SelectCell';

import styles from './Table.module.scss';

export type TableRowProps = {
  index: number;
  headers: TableHeader[];
  rowItem: MappableObject<any>;
  actions?: any;
  fixedActions?: boolean;
  noBorder?: boolean;
  // props for table with select rows
  selectable?: boolean;
  onSelect?: (index: number, selected: boolean) => void;
};

const findImageForIcon = (item: any) => {
  return item.photo_50x50 || item.photo || item.logo || item.cover_image || item.icon;
};

export const TableRow = ({
  index,
  headers,
  rowItem,
  actions,
  fixedActions,
  noBorder,
  selectable,
  onSelect,
}: TableRowProps) => {
  const handleSelectedRow = (selected: boolean) => {
    onSelect && onSelect(index, selected);
  }

  return (
    <tr className={styles.tableRow}>
      {selectable && (
        <SelectCell
          selected={rowItem.selected}
          noBorder={noBorder}
          onSelect={handleSelectedRow}
        />
      )}

      {headers.map((header, i) => {
        if (header.name === 'actions') {
          return <ActionCell key={'asd'} actions={header} rowItem={rowItem} />;
        }
        const title = header.nestedName ? rowItem[header.name]?.[header.nestedName] : rowItem[header.name || ''];
        const iconSrc =
          header.icon ||
          (header.withAvatar ? findImageForIcon(header.nestedName ? rowItem[header.name] : rowItem) : undefined);

        return (
          <TableCell
            {...header}
            key={i}
            iconSrc={iconSrc}
            isTotal={rowItem.isTotal}
            links={rowItem.links}
            noBorder={noBorder}
            title={header.sanitizeValue ? convertSlugToCaption(title) : title}
          />
        );
      })}
      {actions?.length > 0 && <ActionCell actions={actions} rowItem={rowItem} fixedActions={fixedActions} />}
    </tr>
  );
};
