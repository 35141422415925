import Image from 'next/legacy/image';
import { AdminPanelLinkForTable, iconicImagesShape, TableHeader } from 'types';
import { getIconicImageProps } from 'utils/services';
import { convertSlugToCaption, getFormattedDate, getFormattedDateTime } from 'utils/formatters';
import { ColorSwatch } from 'components/ColorSwatch';
import { NextLink } from 'components/NextLink';
import { NextIcon } from 'components/NextImage';

import cx from 'classnames';
import styles from './Table.module.scss';

export type TableCellProps = TableHeader & {
  alignItems?: 'left' | 'center' | 'right';
  dateFormat?: string;
  dateTimeFormat?: string;
  capitalize?: boolean;
  iconShape?: iconicImagesShape;
  iconSrc?: string;
  isBoolean?: boolean;
  isColor?: boolean;
  isTotal?: boolean;
  limitWidth?: boolean;
  links?: AdminPanelLinkForTable[];
  noBorder?: boolean;
  placeholder?: string;
  postfix?: string;
  prefix?: string;
  title: string;
  renderCallback?: (value: any) => any;
};

export const TableCell = ({
  alignItems = 'left',
  capitalize,
  dateFormat,
  dateTimeFormat,
  iconShape = 'round',
  iconSrc,
  isBoolean,
  isColor,
  isTotal,
  limitWidth = true,
  links,
  name,
  noBorder,
  placeholder,
  postfix,
  prefix,
  title,
  renderCallback,
}: TableCellProps) => {
  const iconProps = getIconicImageProps(iconShape);
  const value = Array.isArray(title) ? title.join(', ') : title;

  const renderBooleanIcon = (value: any) => {
    if (value) {
      return <Image src="/icons/checkbox/CheckCircleBlueFilled.svg" alt={value} width={24} height={24} />;
    }
    return <Image src="/icons/checkbox/XCircleRedFilled.svg" alt={value} width={24} height={24} />;
  };

  const renderValueWithPrefix = (value: string, prefix?: string) => {
    if (capitalize && value) {
      value = convertSlugToCaption(value);
    }
    if (!value) {
      return <p>{placeholder ? placeholder : '-'}</p>;
    }
    if (dateFormat) {
      return <p>{getFormattedDate(+value * 1000, dateFormat)}</p>;
    }
    if (dateTimeFormat) {
      return <p>{getFormattedDateTime(+value * 1000, dateTimeFormat)}</p>;
    }
    return <p>{`${prefix ? prefix : ''}${value}${postfix ? postfix : ''}`}</p>;
  };

  const renderContent = () => {
    if (renderCallback) {
      return renderCallback(value);
    }

    return (
      <>
        {iconSrc && (
          <div className={cx(styles.icon, styles[`icon${iconShape}`])}>
            <Image src={iconSrc} alt={value} {...iconProps} />
          </div>
        )}
        {isColor && <ColorSwatch color={title} />}
        {isBoolean ? renderBooleanIcon(value) : renderValueWithPrefix(value, prefix)}
        {links?.length &&
          links.map((link, index) => {
            if (link.targetField === name) {
              return (
                <NextLink key={index} href={link.link} newTab={true}>
                  <NextIcon src={link.icon} customClass={styles.sellLinkIcon} size={20} />
                </NextLink>
              );
            }
            return null;
          })}
      </>
    );
  };

  return (
    <td
      className={cx(styles.tableCell, {
        [styles.noBorderCell]: noBorder,
        [styles.limitWidth]: limitWidth,
        [styles.total]: isTotal,
      })}
    >
      <span className={cx(styles.span, styles[`tableCell${alignItems}`], { [styles.unlimitedWidth]: !limitWidth })}>
        {renderContent()}
      </span>
    </td>
  );
};
