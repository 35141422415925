import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import { JoinedFormFieldProps } from 'types';
import { filterValuesThroughOptions, openDropdownUp } from 'utils/services';
import { toggleParams } from 'utils/toggleParams';
import { InsideItem } from './InsideItem';
import { ListItem } from './ListItem';

import cx from 'classnames';
import styles from './MultiSelect.module.scss';

export const MultiSelect = ({
  blockUpdateByValue,
  customClass,
  errorMessage = 'This field is required',
  fieldName = '',
  onChange,
  placeholder,
  required,
  starRequiredLabel,
  selectItems = [],
  selectTitle = '',
  showErrors,
  trackFieldErrors,
  validator,
  value,
}: JoinedFormFieldProps) => {
  const [isOpened, setOpened] = useState(false);
  const [openUp, setOpenUp] = useState(false);
  const [content, setContent] = useState<Array<string | number>>(value || []);
  const [isValid, setIsValid] = useState(!required);
  const [errorText, setErrorText] = useState(errorMessage);
  const dropdownRef = useRef(null);
  const noItemsToSelect = !selectItems.length;

  useEffect(() => {
    setOpenUp(openDropdownUp(dropdownRef as MutableRefObject<any>));
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleValidation()
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!blockUpdateByValue && value) {
      onChange && onChange(fieldName, value);
      setContent(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectItems?.length && setContent((currentValues) => {
      const filteredValues = filterValuesThroughOptions(currentValues, selectItems)
      onChange && onChange(fieldName, filteredValues as number[]);
      return filteredValues;
    });
  }, [selectItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValidation = () => {
    if (content.length && validator) {
      validateByValidator();
    } else {
      validateByRequired();
    }
  };

  const validateByValidator = () => {
    if (validator && typeof validator !== 'string') {
      const validatorErrorMessage = validator(content);
      setIsValid(!validatorErrorMessage);
      trackFieldErrors && trackFieldErrors(!validatorErrorMessage, fieldName, errorMessage);
      setErrorText(required && !content.length ? errorMessage : (validatorErrorMessage as string));
    } else {
      validateByRequired();
    }
  };

  const validateByRequired = () => {
    if (required && !content.length) {
      setIsValid(false);
      setErrorText(errorMessage);
      trackFieldErrors && trackFieldErrors(false, fieldName, errorMessage);
    } else {
      setIsValid(true);
      setErrorText('');
      trackFieldErrors && trackFieldErrors(true, fieldName, errorMessage);
    }
  };

  const onClickOutsideListener = () => {
    if (isOpened) {
      setOpened(false);
      document.removeEventListener('click', onClickOutsideListener);
    }
  };

  const changeParams = (item: string | number) => {
    setContent(toggleParams(item, content));
    onChange && onChange(fieldName, toggleParams(item, content) as number[]);
  };

  const warnAboutError = !isValid && showErrors;
  const showErrorMessage = warnAboutError && errorText;

  const clearAll = () => {
    setContent([]);
    onChange && onChange(fieldName, []);
  };

  return (
    <div className={cx(styles.wrapper, customClass)}>
      <p className={styles.label}>{selectTitle}{starRequiredLabel ? (<span style={{ color: 'red' }}> *</span>) : null}</p>
      <div
        onMouseLeave={() => {
          document.addEventListener('click', onClickOutsideListener);
        }}
        onClick={() => (noItemsToSelect ? null : setOpened((isOpened) => !isOpened))}
        className={cx(styles.select, {
          [styles.activeSelect]: isOpened && !warnAboutError,
          [styles.error]: warnAboutError,
          [styles.disabled]: noItemsToSelect,
        })}
      >
        {content.length > 3 ? <div className={styles.count}>+{content.length - 3}</div> : null}
        {content.length !== 0 ? (
          content.map?.((value, index) => {
            const targetItem = selectItems.find((item) => item.id === value);
            const caption = targetItem?.title || targetItem?.name;
            if (index < 3) {
              return <InsideItem key={value} deleteItem={changeParams} value={value} caption={caption || ''} />;
            }
          })
        ) : (
          <p className={styles.placeholder}>{placeholder || `Select ${selectTitle}`}</p>
        )}
        <div className={styles.image}>
          <Image
            alt="Image.."
            width={24}
            height={24}
            src={isOpened ? '/icons/arrows/CaretUpDark.svg' : '/icons/arrows/CaretDownDark.svg'}
          />
        </div>
        {content.length > 3 ? (
          <div className={styles.clearContent} onClick={clearAll}>
            <Image alt="Image" width="16" height="16" src="/icons/development/XGrey.svg" />
          </div>
        ) : null}
      </div>
      <div ref={dropdownRef} className={cx(styles.dropdown, { [styles.open]: isOpened, [styles.openUp]: openUp })}>
        {selectItems.map((value) => {
          return (
            <ListItem
              key={value.id}
              value={value.id}
              caption={value.title || value.name}
              content={content}
              changeParams={changeParams}
            />
          );
        })}
      </div>
      {showErrorMessage && (
        <div className={styles.errorAnchor}>
          <p className={styles.errorMessage}>{errorText}</p>
        </div>
      )}
    </div>
  );
};
