export type ColorSwatchProps = { color: string; size?: string };

import styles from './ColorSwatch.module.scss';

export const ColorSwatch = ({ color, size = '40px' }: ColorSwatchProps) => {
  return (
    <div className={styles.container} style={{ width: size, height: size }}>
      <div className={styles.swatch} style={{ backgroundColor: color, width: size, height: size }} />
    </div>
  );
};
