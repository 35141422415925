import { useState, useMemo, Dispatch } from 'react';
import { DropdownOption } from 'types';
import { SELL_STATUSES } from 'consts';
import { getFromToDateStrings } from 'utils/services';
import { convertSlugToCaption } from 'utils/formatters';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';

import styles from './Dashboard.module.scss';

export type SalesReportPanelFiltersProps = {
  dateFilterOptions: DropdownOption[];
  onFiltersChanged: (filters: Record<string, Exclude<any, Function>>) => void;
  setFilters: Dispatch<Record<string, Exclude<any, Function>>>;
};

export type DashboardDateFilterOptionId = 'all' | 'custom' | 'year' | 'month' | 'lastMonth' | 'week' | 'lastWeek';

export type DashboardDateFilterOptions = {
  title: string;
  id: DashboardDateFilterOptionId;
};

const groupOptions = [
  {
    title: 'week',
    id: 'weeks',
  },
  {
    title: 'month',
    id: 'months',
  },
  {
    title: 'years',
    id: 'years',
  },
];

const dashboardStatusExcludeList = ['return_delivered'];

const getStatusesOptions = () =>
  SELL_STATUSES.filter((status: string) => !dashboardStatusExcludeList.includes(status)).map((status) => ({
    id: status,
    title: convertSlugToCaption(status),
  }));

export const SalesReportPanelFilters = ({
  onFiltersChanged,
  dateFilterOptions,
  setFilters,
}: SalesReportPanelFiltersProps) => {
  const [dateFilter, setDateFilter] = useState<DashboardDateFilterOptionId>('month');
  const [rangePickerValue, setRangePickerValue] = useState<Array<string | null> | undefined>(
    getFromToDateStrings('month'),
  );

  const statusesOptions = useMemo(getStatusesOptions, []);

  const onChange = (name: string, newData: Record<string, Exclude<any, Function>> | any) => {
    setFilters((previous: Record<string, Exclude<any, Function>> | any) => {
      const newFilters = { ...previous };
      newFilters[name] = newData;

      onFiltersChanged(newFilters);
      return newFilters;
    });
  };

  const onDateFilterChanged = (dateFilter: DashboardDateFilterOptionId) => {
    const [newFrom, newTo] = getFromToDateStrings(dateFilter) || [];
    setDateFilter(dateFilter);
    setFilters((previous: Record<string, Exclude<any, Function>> | any) => {
      if (dateFilter === 'custom') {
        return previous;
      }
      setRangePickerValue([newFrom, newTo]);
      const newFilters = { ...previous };
      if (dateFilter === 'all') {
        delete newFilters.from_date;
        delete newFilters.to_date;
      } else {
        newFilters.from_date = newFrom;
        newFilters.to_date = newTo;
      }
      onFiltersChanged(newFilters);
      return newFilters;
    });
  };

  return (
    <div className={styles.filtersContainer}>
      <Dropdown
        label="Time Period:"
        blockUpdateByValue={true}
        isThin={true}
        options={dateFilterOptions}
        onChange={(_, value) => onDateFilterChanged(value)}
        value={dateFilter}
      />
      <DateRangePicker
        fieldName="from_date#to_date"
        onChange={onChange}
        isDisabled={dateFilter !== 'custom'}
        value={rangePickerValue?.length ? rangePickerValue : undefined}
        label="Start date:#End date:"
      />
      <Dropdown
        blockUpdateByValue={true}
        isThin={true}
        options={groupOptions}
        value="weeks"
        fieldName="group"
        onChange={onChange}
        label="Group by:"
      />
      <MultiSelect
        blockUpdateByValue={true}
        isThin={true}
        selectItems={statusesOptions}
        fieldName="statuses"
        onChange={onChange}
        selectTitle="Status:"
        placeholder="All"
      />
    </div>
  );
};
