import { CurrentUser, PermissionPrefix, PermissionSuffix } from 'types';

export const userCan = (
  user: CurrentUser | null | undefined,
  permissions: string | PermissionPrefix | PermissionPrefix[] | string[],
  permissionSuffix: PermissionSuffix | string | null | undefined = null
) => {
  if (!user) {
    return false;
  }

  if (user.role === 'super_admin') {
    return true;
  }

  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  for (let permission of permissions) {
    permission = permissionSuffix ? `${permission}_${permissionSuffix}` : permission;

    if (user.permissions?.includes(permission)) {
      return true;
    }
  }
}
