import {CurrentUser, DashboardSalesPeriodData, DashboardStatistic, DropdownOption, TopBrands, TopUsers} from 'types';
import { PermissionChecker } from 'containers/PermissionVerificator';
import { RateCard } from 'components/RateCard';
import { AdminPanelWrapper } from '../AdminPanelWrapper';
import { SalesReportPanel } from './SalesReportPanel';
import { BalancesReportPanel } from './BalancesReportPanel';

import styles from './Dashboard.module.scss';

export type DashboardProps = {
  dateFilterOptions: DropdownOption[];
  filters: Record<string, Exclude<any, Function>>;
  topUsers: TopUsers[];
  topBrands: TopBrands[];
  reports: DashboardSalesPeriodData[];
  statistic: DashboardStatistic;
  user?: CurrentUser;
};

export const Dashboard = (props: DashboardProps) => {
  const {
    // topUsers,
    // topBrands,
    dateFilterOptions,
    filters,
    reports,
    statistic,
  } = props;

  return (
    <PermissionChecker {...props} permissionPrefix="dashboard">
      <AdminPanelWrapper title="Dashboard" heading="Dashboard">
        <div className={styles.container}>
          <div className={styles.upperBlock}>
            <RateCard
              iconSrc="/icons/people/UsersWhite.svg"
              title="New users this week"
              value={`${statistic.week_new_users}`}
            />
            <RateCard
              iconSrc="/icons/design/PencilCircleWhite.svg"
              title="Total sells this week"
              value={`$ ${statistic.week_total_sell}`}
            />
            <RateCard
              iconSrc="/icons/design/SquareHalfWhite.svg"
              title="Items Sold"
              value={`${statistic.items_sold}`}
            />
            <RateCard
              iconSrc="/icons/commerce/MoneyWhite.svg"
              title="Total Users’ Balance"
              value={`${statistic.users_balances}`}
            />
          </div>
          {/*<TopBrandUserPanel topUsers={topUsers} topBrands={topBrands} />*/}
          <BalancesReportPanel />
          <SalesReportPanel reports={reports} dateFilterOptions={dateFilterOptions} filters={filters}/>
        </div>
      </AdminPanelWrapper>
    </PermissionChecker>
  );
};
