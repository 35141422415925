import { Checkbox } from 'components/Inputs/Checkbox';

import cx from 'classnames';
import styles from './Table.module.scss';

export type SelectCellProps = {
  noBorder?: boolean;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
};

export const SelectCell = ({
  noBorder,
  selected,
  onSelect,
}: SelectCellProps) => {
  const handleChange = (val, checked) => {
    onSelect(checked);
  }

  return (
    <td
      className={cx(styles.tableCell, {
        [styles.noBorderCell]: noBorder,
      })}
    >
      <Checkbox
        checked={selected}
        size="small"
        onChange={handleChange}
      />
    </td>
  );
};
