import { TableHeader } from 'types';

export const topUserHeaders: TableHeader[] = [
  {
    name: 'rate',
    title: 'Rank',
    isSortable: true,
    iconShape: 'icon',
    icon: '/icons/Star.svg',
  },
  {
    name: 'username',
    title: 'Username',
    withAvatar: true,
  },
  {
    name: 'sold_items',
    title: 'Sold Items',
    isSortable: true,
    alignItems: 'right',
  },
  {
    name: 'sold_amount',
    title: 'Sold USD',
    isSortable: true,
    alignItems: 'right',
    prefix: '$',
  },
];

export const topBrandsHeaders: TableHeader[] = [
  {
    name: 'rate',
    title: 'Rank',
    // isSortable: true,
    iconShape: 'icon',
    icon: '/icons/Star.svg',
  },
  {
    name: 'title',
    title: 'Title',
    withAvatar: true,
    iconShape: 'square',
  },
  {
    name: 'sold_items',
    title: 'Sold Items',
    isSortable: true,
    alignItems: 'right',
  },
  {
    name: 'sold_amount',
    title: 'Sold USD',
    isSortable: true,
    alignItems: 'right',
    prefix: '$',
  },
];

export const SalesReportHeaders: TableHeader[] = [
  {
    name: 'period',
    title: 'Period',
    alignItems: 'left',
    limitWidth: false,
  },
  {
    name: 'fact_price',
    title: 'Fact Price Total',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'shipping_amount',
    title: 'Shipping Total',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'shipping_margin_amount',
    title: 'Shipping Margin',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'sales_tax',
    title: 'Sales Tax Total',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'total_buyer_fee',
    title: 'Buyer Fee',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'total_fee',
    title: 'Seller Fee',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'use_balance',
    title: 'Use Balance',
    alignItems: 'left',
    postfix: ' USD',
  },
  {
    name: 'charity_amount',
    title: 'Charity Amount',
    alignItems: 'left',
    postfix: ' USD',
  },
  // {
  //   name: 'discounts',
  //   title: 'Discounts',
  //   alignItems: 'left',
  //   postfix: ' USD'
  // },
];
