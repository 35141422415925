import {
  useAppSelector,
  useAppDispatch,
  selectAdminPanelFilters,
  getFilteredAdminPanel,
  setAdminPanelFilters,
} from 'store';
import { SearchInput } from 'components/SearchInput';

export type ReduxDrivenSearchInputProps = {
  placeholder?: string;
};

export const ReduxDrivenSearchInput = ({ placeholder }: ReduxDrivenSearchInputProps) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectAdminPanelFilters);

  const onSearch = (value: string) => {
    dispatch(getFilteredAdminPanel({ ...filters, search: value, page: 1 }));
    dispatch(setAdminPanelFilters({ ...filters, search: value, page: 1 }));
  };

  return <SearchInput onSearch={onSearch} value={filters?.search} placeholder={placeholder} />;
};
