import { ReactElement } from 'react';
import { Button } from 'components/Button';
import { Table, SelectableAction } from 'components/Table';
import { DashboardItemForTable, TableHeader, ButtonType } from 'types';

import cx from 'classnames';
import styles from './CommonContentTable.module.scss';

export type EditAction = {
  name: string;
  action: (id: string, instance?: any) => any;
  icon: string;
  conditionChecker?: (value: any) => boolean;
  conditionField?: string;
  altIcon?: string;
  altAction?: (id: string, instance?: any) => any;
  hideAltAction?: boolean;
  hint?: string;
};

export type CommonContentTableProps = {
  additionalFilters?: ReactElement;
  buttonCallback: () => void;
  buttonCaption: string;
  buttonIcon: string;
  buttonType?: ButtonType;
  canEdit: boolean;
  children?: ReactElement;
  panelChildren?: ReactElement;
  data?: DashboardItemForTable[];
  editActions: EditAction[];
  fixedActions?: boolean;
  isLoading: boolean;
  headChildren: any;
  hideButton?: boolean;
  onSort: (field: string, direction: 'asc' | 'desc' | 'none') => void;
  pagination?: ReactElement;
  searchInput?: ReactElement;
  tableHeaders: TableHeader[];
  viewActions?: EditAction[];
  // classes
  leftToolbarClass?: string;
  rightToolbarClass?: string;
  // props for table with select rows
  selectableTable?: boolean;
  selectableTableActions?: SelectableAction[];
};

export const CommonContentTable = ({
  additionalFilters,
  buttonCallback,
  buttonCaption,
  buttonIcon,
  buttonType,
  canEdit,
  children,
  panelChildren,
  data,
  editActions,
  fixedActions,
  headChildren,
  hideButton,
  isLoading,
  onSort,
  pagination,
  searchInput,
  tableHeaders,
  viewActions,
  leftToolbarClass,
  rightToolbarClass,
  selectableTable,
  selectableTableActions,
}: CommonContentTableProps) => {
  return (
    <>
      <div className={styles.container}>
        {panelChildren && panelChildren}
        <div className={styles.toolBlock}>
          <div className={cx(styles.toolBlockLeftSide, leftToolbarClass)}>
            {searchInput && searchInput}
            {additionalFilters && additionalFilters}
            {children && children}
          </div>
          <div className={cx(styles.toolBlockRightSide, rightToolbarClass)}>
            {!!headChildren && headChildren}
            {!hideButton && (
              <Button
                caption={buttonCaption}
                action={buttonCallback}
                iconSrc={buttonIcon}
                buttonType={buttonType}
                shape="auto"
              />
            )}
          </div>
        </div>
        <Table
          headers={tableHeaders}
          actions={canEdit ? [...(viewActions || []), ...(editActions || [])] : viewActions}
          content={data}
          onSort={onSort}
          isLoading={isLoading}
          fixedActions={fixedActions}
          selectable={selectableTable}
          selectableActions={selectableTableActions}
        />
        {pagination && pagination}
      </div>
    </>
  );
};
