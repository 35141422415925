import { useEffect, useState } from 'react';
import Head from 'next/head';
import { AdminPanelPageProps, DashboardItemForEditing, FormNotifications } from 'types';
import { postAdminPanelUnitData, updateAdminPanelUnit } from 'api';
import { formatErrorsForFormNotification } from 'utils/services';
import { saveSessionSuccess } from 'utils/localStoreTools';
import { DynamicForm } from 'components/DynamicForm';
import { PermissionChecker } from 'containers/PermissionVerificator';
import { AdminPanelWrapper } from '../AdminPanelWrapper';
import { Snackbar, SnackbarProps } from 'components/Snackbar';

import cx from 'classnames';
import styles from './CommonAdminPanel.module.scss';

export const AdminPanelUnit = (props: AdminPanelPageProps) => {
  const {
    api,
    apiRoute = api,
    apiInstanceName,
    buttonCaption,
    cancelCaption,
    dataRef,
    errorsRef,
    fields,
    heading,
    headTitle,
    query = {},
    instance = {} as DashboardItemForEditing,
    instanceId,
    liveUpdate,
    notifications: formNotification,
    saveAction,
    redirectAfterSuccessfullySave = true,
    sidebarMarker,
    skipSend = false,
    permissionPrefix,
    title,
    user,
    wideForm,
    withFile = false,
  } = props;
  const [notifications, setNotifications] = useState<Record<any, FormNotifications> | undefined>(formNotification);
  const isCreate = instanceId === 'create';
  const actionButtonCaption = buttonCaption || (isCreate ? 'Create' : 'Update');
  const canEdit = user?.role === 'super_admin' || user?.permissions?.includes(`${permissionPrefix || api}_edit`);
  const [snackNotification, setSnackNotification] = useState<SnackbarProps | null>(null);

  useEffect(() => {
    if (!Object.keys(instance).length && instanceId !== 'create') {
      goToBoard();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToBoard = () => {
    return (window.location.href = `/admin-panel/${apiRoute}`);
  };

  const onSend = async (data: any) => {
    const callback = isCreate ? postAdminPanelUnitData : updateAdminPanelUnit;
    const response = await callback(api, { ...query, ...data }, instanceId, withFile);
    if (response.ok) {
      if (redirectAfterSuccessfullySave) {
        saveSessionSuccess(`${apiInstanceName} ${isCreate ? 'created' : 'updated'} successfully`);
        goToBoard();
      } else {
        setSnackNotification({
          header: `${apiInstanceName} ${isCreate ? 'created' : 'updated'} successfully`,
        });
      }
    } else {
      setNotifications(formatErrorsForFormNotification(response));
    }
  };

  const onCancel = () => {
    goToBoard();
  };

  return (
    <PermissionChecker {...props}>
      <Head>
        <title>{headTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width, height=device-height" />
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
      </Head>
      <AdminPanelWrapper title={title} heading={heading} sidebarMarker={sidebarMarker}>
        <div className={cx(styles.formWrapper, { [styles.wideForm]: wideForm })}>
          <DynamicForm
            buttonCaption={actionButtonCaption}
            buttonsShape={'slim'}
            cancelCaption={cancelCaption || 'Cancel'}
            dataRef={dataRef}
            disableAll={!canEdit}
            errorsRef={errorsRef}
            fields={fields}
            liveUpdate={liveUpdate}
            marginMode="low"
            notifications={notifications}
            onCancel={onCancel}
            onSend={skipSend ? undefined : saveAction ? saveAction : onSend}
          />
          {snackNotification && <Snackbar {...snackNotification} onClose={() => setSnackNotification(null)} />}
        </div>
      </AdminPanelWrapper>
    </PermissionChecker>
  );
};
