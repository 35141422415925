import { PaginationButton } from './PaginationButton';

import styles from './PaginationProvider.module.scss';

export type PaginationProviderProps = {
  currentPage: number;
  from: number;
  lastPage: number;
  to: number;
  total: number;
  onPaginationChange: (value: number) => void;
};

export const PaginationProvider = ({
  currentPage,
  lastPage,
  from,
  to,
  total,
  onPaginationChange,
}: PaginationProviderProps) => {
  if (lastPage > 1) {
    return (
      <div className={styles.container}>
        <div className={styles.legend}>{`Showing ${from}-${to} of ${total} items`}</div>
        <div className={styles.buttonsBlock}>
          <PaginationButton
            value={1}
            callback={onPaginationChange}
            iconSrc="/icons/arrows/CaretDoubleLeft"
            active={false}
            disabled={!(currentPage - 1)}
          />
          <PaginationButton
            value={currentPage - 1}
            callback={onPaginationChange}
            iconSrc="/icons/arrows/CaretLeft"
            active={false}
            disabled={!(currentPage - 1)}
          />
          {currentPage - 3 > 0 && (
            <PaginationButton value={'...'} callback={onPaginationChange} active={false} disabled={false} />
          )}
          {currentPage - 2 > 0 && (
            <PaginationButton value={currentPage - 2} callback={onPaginationChange} active={false} disabled={false} />
          )}
          {currentPage - 1 > 0 && (
            <PaginationButton value={currentPage - 1} callback={onPaginationChange} active={false} disabled={false} />
          )}
          <PaginationButton value={currentPage} active={true} disabled={true} />
          {currentPage < lastPage && (
            <PaginationButton value={currentPage + 1} callback={onPaginationChange} active={false} disabled={false} />
          )}
          {currentPage + 1 < lastPage && (
            <PaginationButton value={currentPage + 2} callback={onPaginationChange} active={false} disabled={false} />
          )}
          {currentPage + 2 < lastPage && <PaginationButton value={'...'} active={false} disabled={false} />}
          <PaginationButton
            value={currentPage + 1}
            callback={onPaginationChange}
            iconSrc="/icons/arrows/CaretRight"
            active={false}
            disabled={currentPage >= lastPage}
          />
          <PaginationButton
            value={lastPage}
            callback={onPaginationChange}
            iconSrc="/icons/arrows/CaretDoubleRight"
            active={false}
            disabled={currentPage >= lastPage}
          />
        </div>
      </div>
    );
  }
  return null;
};
