import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Image from 'next/legacy/image';
import { JoinedFormFieldProps } from 'types';
import { getFormattedDate } from 'utils/formatters';

import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import styles from './DateRangePicker.module.scss';

export const DateRangePicker = ({
  dateFormat,
  fieldName = '',
  value,
  onChange,
  isDisabled,
  label,
  zIndex = 10,
}: JoinedFormFieldProps) => {
  const [fieldFrom, fieldTo] = fieldName.split('#');
  const [startDate, setStartDate] = useState(value?.[0] ? value[0] : false);
  const [endDate, setEndDate] = useState(value?.[1] ? value[1] : false);
  const [firstLabel, secondLabel] = label && typeof label === 'string' ? label.split('#') : [];

  const inputLeft = useRef(null);
  const inputRight = useRef(null);

  useEffect(() => {
    setStartDate(value?.[0] ? value[0] : false);
    setEndDate(value?.[1] ? value[1] : false);
  }, [value]);

  const url = '/icons/office/CalendarBlank.svg';

  const clickHandler = (ownRef: any) => {
    ownRef?.current?.input && ownRef.current?.input.focus();
  }; // Could find type for this Event

  const handleChanges = (name: string, dateString: any) => {
    const stringDate = dateString.toLocaleDateString('en-US');
    onChange && onChange(name, dateFormat ? getFormattedDate(dateString, dateFormat) : stringDate);
    if (name === fieldFrom) {
      setStartDate(stringDate);
    } else {
      setEndDate(stringDate);
    }
  };

  return (
    <div className={styles.rangePicker} style={{ zIndex }}>
      <div className={styles.wrapper}>
        {firstLabel && <p className={styles.label}>{firstLabel}</p>}
        {!startDate && !firstLabel && (
          <div onClick={() => clickHandler(inputLeft)} className={styles.placeholder}>
            Start date
          </div>
        )}
        <div className={styles.icons}>
          <Image src={url} width={24} height={24} alt={url} />
        </div>
        <DatePicker
          ref={inputLeft}
          dateFormat="MM/dd/yyyy"
          maxDate={endDate ? moment(endDate).toDate() : null}
          className={cx(styles.picker, { [styles.disabled]: isDisabled })}
          selected={startDate ? moment(startDate).toDate() : null}
          onChange={(date: any) => handleChanges(fieldFrom, date)}
          wrapperClassName={styles.datepickerCustomWrapperClass}
          disabled={isDisabled}
        />
      </div>
      <div className={styles.wrapper}>
        {secondLabel && <p className={styles.label}>{secondLabel}</p>}
        {!endDate && !secondLabel && (
          <div onClick={() => clickHandler(inputRight)} className={styles.placeholder}>
            End date
          </div>
        )}
        <div className={styles.icons}>
          <Image src={url} width={24} height={24} alt={url} />
        </div>
        <DatePicker
          ref={inputRight}
          dateFormat="MM/dd/yyyy"
          minDate={startDate ? moment(startDate).toDate() : null}
          className={cx(styles.picker, { [styles.disabled]: isDisabled })}
          selected={endDate ? moment(endDate).toDate() : null}
          onChange={(date: any) => handleChanges(fieldTo, date)}
          wrapperClassName={styles.datepickerCustomWrapperClass}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
