import { AdminPanelTab } from 'types';

import cx from 'classnames';
import styles from './UnderHeaderTabs.module.scss';

export type UnderHeaderTabsProps = {
  active?: string;
  tabs: AdminPanelTab[];
  alignLeft?: boolean;
  marginBottom?: boolean;
};

export const UnderHeaderTabs = ({ tabs, active, alignLeft, marginBottom }: UnderHeaderTabsProps) => {
  return (
    <div className={cx(styles.container, { [styles.alignLeft]: alignLeft, [styles.marginBottom]: marginBottom })}>
      {tabs &&
        tabs.map((tab) => {
          const isActive = tab.name === active;

          return (
            <div
              key={tab.name}
              className={cx(styles.tab, { [styles.active]: isActive })}
              onClick={() => {
                tab.callback(tab.name);
              }}
            >
              {tab.caption}
            </div>
          );
        })}
    </div>
  );
};
