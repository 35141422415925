import { Checkbox } from 'components/Inputs/Checkbox';

import cx from 'classnames';
import styles from './Table.module.scss';

export type SelectHeaderCellProps = {
  noBorder?: boolean;
  selected?: boolean;
  onSelect: () => void;
};

export const SelectHeaderCell = ({
  noBorder,
  selected,
  onSelect,
}: SelectHeaderCellProps) => {
  return (
    <th className={cx(styles.headerCell, { [styles.noBorderCell]: noBorder })}>
      <Checkbox
        checked={selected}
        size="small"
        onChange={onSelect}
      />
    </th>
  );
};
