import Image from 'next/legacy/image';
import { SelectableAction } from 'components/Table/Table';

import cx from 'classnames';
import styles from './Table.module.scss';

export type SelectActionsProps = {
  actions: SelectableAction[];
  active: boolean;
  onAction: (action: SelectableAction) => void;
};

export const SelectActions = ({
  actions,
  active,
  onAction,
}: SelectActionsProps) => {
  const handleAction = (action: SelectableAction, disabled?: boolean) => {
    if (active && !disabled) {
      onAction(action);
    }
  }

  return (
    <div className={styles.selectActionsContainer}>
      {actions.map((action, idx) => (
        <div
          key={idx}
          className={cx(styles.selectAction, { [styles.selectActionDisabled]: !active || !!action.disabled })}
          onClick={() => handleAction(action, action.disabled)}
        >
          <Image src={(active && !action.disabled) ? action.icon : action.iconDisabled} height={20} width={20} />
          {action.text ? (<>&nbsp;&nbsp;{action.text}</>) : ''}
        </div>
      ))}
    </div>
  );
};
