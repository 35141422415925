import { ChangeEvent, useEffect, useState } from 'react';
import { CheckboxProps } from 'types';

import cx from 'classnames';
import styles from './Inputs.module.scss';

export const Checkbox = ({
  checked,
  className,
  containerClassName,
  errorMessage,
  label,
  fieldName = '',
  onChange,
  required,
  trackFieldErrors,
  showErrors,
  size,
}: CheckboxProps) => {
  const [currentValue, setCurrentValue] = useState<boolean>(!!checked);
  const [isValid, setIsValid] = useState((required && checked) || !required);

  useEffect(() => {
    onChange && onChange(fieldName, !!checked);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trackFieldErrors && trackFieldErrors(required ? isValid : true, fieldName, errorMessage);
    return () => {
      trackFieldErrors && trackFieldErrors(true, fieldName, errorMessage);
    };
  }, [required, isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentValue(!!checked);
  }, [checked]);

  const trackChanges = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.name, event.target.checked);
    setCurrentValue(event.target.checked);
    setIsValid((required && event.target.checked) || !required);
  };

  const showErrorMessage = !isValid && showErrors && errorMessage;

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={cx(styles.checkboxContainer, containerClassName, { [styles.smallCheckbox]: size === 'small', })}>
        <input
          className={styles.input}
          type="checkbox"
          checked={currentValue}
          id={fieldName}
          name={fieldName}
          onChange={trackChanges}
        />
        <label className={styles.label} htmlFor={fieldName}>
          {label}
        </label>
      </div>
      {showErrorMessage && (
        <div className={styles.errorMessage}>
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
