import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './Table.module.scss';

export type ActionCellProps = {
  actions: any;
  rowItem: Record<string, any>;
  fixedActions?: boolean;
};

export const ActionCell = ({ actions = [], rowItem, fixedActions }: ActionCellProps) => {
  return (
    <td className={cx(styles.actions, {[styles.fixedActions]: fixedActions})}>
      <span>
        {actions.map((action: any) => {
          const { conditionField } = action;

          if (!conditionField) {
            return (
              <div
                key={action.name}
                onClick={() => {
                  action.action(rowItem.id, rowItem);
                }}
              >
                <Image src={action.icon} height={24} width={24} alt={action.name} />
              </div>
            );
          }

          const { conditionChecker = (field: string) => !!field } = action;
          const isPassed = conditionChecker(rowItem[conditionField]);

          if (!isPassed && action.hideAltAction) {
            return null;
          }

          return (
            <div
              key={action.name}
              onClick={() => {
                isPassed
                  ? action.action && action.action(rowItem.id, rowItem)
                  : action.altAction && action.altAction(rowItem.id, rowItem);
              }}
            >
              <Image src={isPassed ? action.icon : action.altIcon} height={24} width={24} alt={action.name} />
            </div>
          );
        })}
      </span>
    </td>
  );
};
