import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './InsideItem.module.scss';

export type InsideItemProps = {
  deleteItem?: (item: string | number) => void;
  value: string | number;
  type?: string;
  caption?: string;
  isMargin?: boolean;
  disabled?: boolean;
};

export const InsideItem = ({ isMargin, deleteItem, value, type = 'blue', caption, disabled }: InsideItemProps) => {
  const stopOwnPropagation = (event: any) => {
    event.stopPropagation();
  };

  return (
    <>
      <div
        onClick={stopOwnPropagation}
        className={cx(
          styles.inside,
          styles[`${type}`],
          { [styles.marginTop]: isMargin },
          { [styles.disabled]: disabled },
        )}
      >
        <p className={styles[`${type}`]}>{caption}</p>
        {!disabled && (
          <div
            className={styles.image}
            onClick={() => deleteItem && deleteItem(value)}
          >
            <Image
              alt="image"
              width="16"
              height="16"
              src={type === 'blue' ? '/icons/development/X.svg' : '/icons/development/XGrey.svg'}
            />
          </div>
        )}
      </div>
    </>
  );
};
