import { useState } from 'react';
import { DashboardSalesPeriodData, DropdownOption } from 'types';
import { exportDashboardSalesReports, getAdminPanelBoard } from 'api';
import { sanitizeDashboardReports } from 'utils/formatters';
import { formatServerErrorsForNotification } from 'utils/services';
import { openSnackBar, useAppDispatch } from 'store';
import { Table } from 'components/Table';
import { Button } from 'components/Button';
import { SalesReportHeaders } from './tableHeaders';
import { SalesReportPanelFilters } from './SalesReportPanelFilters';

import styles from './Dashboard.module.scss';

export type SalesReportProps = {
  dateFilterOptions: DropdownOption[];
  filters: Record<string, Exclude<any, Function>>;
  reports: DashboardSalesPeriodData[];
};

export const SalesReportPanel = ({ reports, dateFilterOptions, filters: initFilters }: SalesReportProps) => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<Record<string, Exclude<any, Function>>>(initFilters);
  const [reportsData, setReportsData] = useState<DashboardSalesPeriodData[]>(reports);
  const [isLoading, setIsLoading] = useState(false);

  const onFiltersChanged = async (newFilters: Record<string, Exclude<any, Function>>) => {
    setIsLoading(true);
    const reportResponse = await getAdminPanelBoard(
      'dashboard/reports/sales',
      newFilters,
      undefined,
      sanitizeDashboardReports,
    );
    setReportsData(reportResponse);
    setIsLoading(false);
  };

  const onExport = async () => {
    const exportResponse = await exportDashboardSalesReports(filters);
    if (exportResponse) {
      // it'll return null if export successful
      dispatch(openSnackBar(formatServerErrorsForNotification(exportResponse)));
    }
  };

  return (
    <div className={styles.salesReportContainer}>
      <h2>Sales Report</h2>
      <SalesReportPanelFilters
        dateFilterOptions={dateFilterOptions}
        onFiltersChanged={onFiltersChanged}
        setFilters={setFilters}
      />
      <Table headers={SalesReportHeaders} content={reportsData} isLoading={isLoading} />
      <div className={styles.salesExportButtonContainer}>
        <Button
          action={onExport}
          caption="Export"
          shape="auto"
          iconSrc="/icons/design/DownloadSimpleBlack.svg"
          buttonType="white"
        />

      </div>
    </div>
  );
};
