import { useState } from 'react';
import { NextIcon } from 'components/NextImage';
import { SortingStatus } from 'types';
import { sortIconsSrcMap } from 'consts/general';

import cx from 'classnames';
import styles from './Table.module.scss';

export type TableHeaderCellProps = {
  alignItems?: 'left' | 'center' | 'right';
  api?: string;
  columnName?: string;
  dateFormat?: string;
  isSortable?: boolean;
  noBorder?: boolean;
  onSort?: (columnName: string, direction: SortingStatus) => void;
  title?: string;
};

const iconChangingStatusesMap: Record<SortingStatus, SortingStatus> = {
  asc: 'desc',
  desc: 'none',
  none: 'asc',
};

export const TableHeaderCell = ({
  alignItems = 'left',
  api,
  columnName,
  isSortable = false,
  noBorder,
  onSort = () => {},
  title,
}: TableHeaderCellProps) => {
  const [iconStatus, setIconStatus] = useState<SortingStatus>('none');

  const onSortIconClick = () => {
    const newSorting = iconChangingStatusesMap[iconStatus];
    onSort(api || columnName, newSorting);
    setIconStatus(newSorting);
  };

  return (
    <th className={cx(styles.headerCell, styles[`align${alignItems}`], { [styles.noBorderCell]: noBorder })}>
      <span>
        {title}
        {isSortable && (
          <NextIcon
            customClass={styles.sortWrapper}
            src={sortIconsSrcMap[iconStatus] || '/'}
            alt={`sort ${columnName}`}
            onClick={onSortIconClick}
          />
        )}
      </span>
    </th>
  );
};
